.PDFModal {
	width: 100vw;
	height: 100vh;
}

.CardContainer {
	width: 80vw;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: white;
}

.GridContainer {
	display: grid;
	margin: 20px;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}

.One {
	grid-column: 1;
}

.Two {
	grid-column: 2;
}
