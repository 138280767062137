.divider {
	margin: 0px;
	margin-top: 4px;
	margin-bottom: 6px;
	background-color: rgb(173, 216, 230, 0.5);
}

.header {
	display: flex;
	background-color: rgb(25, 135, 174);
	justify-content: center;
	border-bottom-right-radius: 100px;
	border-bottom-left-radius: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin-bottom: 10px;
	box-shadow: 1px 0px 10px 1px rgb(236, 224, 224);
	width: 100%;
	align-self: center;
	padding: 4px 0px;
}

.header h2 {
	color: white;
}

.content {
	padding: 10px 40px 10px 40px;
}
