.master-container {
	width: 90vw;
	text-align: center;
}

.carousel {
	/* background-color: green; */
	height: fit-content;
	padding: 45px;
}

.quote-container {
	display: flex;
	width: 50px;
	padding: 0px 10px;
}

.quote-text {
	font-family: "playfair display";
	font-size: 18px;
	line-height: 24px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	color: white;
	text-shadow: #fc0 1px 2px 15px;
}

.quote-by-container {
	margin-top: 12px;
	float: right;
}

.quote-by-name {
	color: white;
	font-size: 16px;
	align-self: flex-end;
	text-shadow: rgb(255, 247, 215) 0px 2px 10px;
}

.quote-character-left {
	color: white;
	font-size: 40px;
	line-height: 40px;
	text-align: left;
}

.quote-character-right {
	color: white;
	font-size: 40px;
	line-height: 40px;
	text-align: right;
}
